import React from "react"
import { toCapitalCase } from "../../utils"

function LikedCards(props) {
  let items = Object.entries(props)[0]
  console.log(items)
  return (
    <div className="flex items-center justify-between mb-4">
      <div>
        <h2 className="text-3xl text-red-600">
          You liked {toCapitalCase(items[0])} {items[1].title}
        </h2>
      </div>
    </div>
  )
}

export default LikedCards
