import React, { useContext, useEffect, useState } from "react"
import {
  getMyBookmarks,
  getMyBookmarkSingle,
  getRetailersListing,
  getStatusListing,
  sessToken,
} from "../helpers/DrupalHelper"
import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid"

import LayoutPage from "../components/Layout/Pages"
import BannerSimple from "../components/Commons/BannerSimple"
import Button from "../components/Elements/Button"
import StateContext from "../context/state/StateContext"
import LikedCards from "../components/Cards/LikedCards"
import BrandGrid from "../components/Cards/BrandGrid"
import ProductGrid from "../components/Cards/ProductGridCard"
import RetailerListCard from "../components/Cards/RetailerListCard"
import { classNames } from "../utils"
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Tab,
  Transition,
} from "@headlessui/react"
import DataContext from "../context/Data/DataContext"
import LikeHits from "../components/Elements/LikeHits"
import Skeleton from "../components/Skeleton"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import AccessDenied from "../components/AccessDenied"

const Tabs = props => {
  const { token } = useContext(StateContext)
  const { myBookmarksItems } = useContext(DataContext).data
  // let { newActivity } = props

  const [currentTab, setCurrentTab] = useState("Company")
  const [retailersData, setRetailersData] = useState([])
  const [storesData, setStoresData] = useState([])
  const [assetsData, setAssetsData] = useState([])
  const [productsData, setProductsData] = useState([])
  const [brandsData, setBrandsData] = useState([])

  const tabss = [
    {
      name: `All (${myBookmarksItems.total})`,
      href: "#",
      type: "total",
      title: "Items",
    },
    {
      name: `Products (${myBookmarksItems.products})`,
      href: "#",
      type: "products",
      title: "Products",
    },
    {
      name: `Assets (${myBookmarksItems.assets})`,
      href: "#",
      title: "Assets",
      type: "assets",
    },
    {
      name: `Retailers (${myBookmarksItems.retailers})`,
      href: "#",
      title: "Retailers",
      type: "retailers",
    },
    {
      name: `Stores (${myBookmarksItems.stores})`,
      href: "#",
      title: "Stores",
      type: "stores",
    },
    {
      name: `Brands (${myBookmarksItems.brands})`,
      href: "#",
      title: "Brands",
      type: "brands",
    },
  ]
  const onTabChange = v => {
    props.updateTitle(v.title)
  }
  const [tabs, setTabs] = useState([])
  useEffect(() => {
    if (Object.keys(myBookmarksItems).length) {
      let zeross = []
      Object.keys(myBookmarksItems).map(v => {
        if (myBookmarksItems[v] === 0) {
          zeross.push(v)
        }
      })
      let d = tabss.filter(c => !zeross.includes(c.type))
      setTabs([...d])
    }
  }, [myBookmarksItems])
  useEffect(async () => {
    let pData = await getMyBookmarkSingle(token, "product")
    setProductsData(pData)
    let aData = await getMyBookmarkSingle(token, "asset")
    setAssetsData(aData)
    let rData = await getMyBookmarkSingle(token, "retailer")
    setRetailersData(rData)
    let sData = await getMyBookmarkSingle(token, "store")
    setStoresData(sData)
    let bData = await getMyBookmarkSingle(token, "brand")
    setBrandsData(bData)
  }, [])

  return (
    <div>
      {Object.keys(myBookmarksItems).length ? (
        <Tab.Group onChange={v => onTabChange(tabs[v])}>
          <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
            {tabs.map((item, k) => (
              <Tab
                key={k}
                className={({ selected }) =>
                  classNames(
                    selected
                      ? "border-[#222] text-[#222]"
                      : "border-transparent text-[#222] hover:text-gray-500",
                    "whitespace-nowrap pt-[30px] pb-[28px] px-1 border-b-2 text-med-16"
                  )
                }
              >
                {item.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-[30px]">
            {tabs.map((item, k) => {
              if (item.type == "total") {
                return (
                  <Tab.Panel key={k}>
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {props.list.map((item, k) => {
                        // console.log(item)
                        // console.log("item")
                        let data = Object.entries(item)[0]
                        // console.log(data)
                        // console.log(propData)
                        // console.log("propData")
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
              if (item.type == "products") {
                return (
                  <Tab.Panel key={k}>
                    {/* {posts} */}
                    {/*<div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">*/}
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {productsData.map((item, k) => {
                        let data = Object.entries(item)[0]
                        console.log(data)
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
              if (item.type == "assets") {
                return (
                  <Tab.Panel key={k}>
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {assetsData.map((item, k) => {
                        let data = Object.entries(item)[0]
                        console.log(data)
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
              if (item.type == "retailers") {
                return (
                  <Tab.Panel key={k}>
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {retailersData.map((item, k) => {
                        let data = Object.entries(item)[0]
                        console.log(data)
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
              if (item.type == "stores") {
                return (
                  <Tab.Panel key={k}>
                    {/* {posts} */}
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {storesData.map((item, k) => {
                        let data = Object.entries(item)[0]
                        console.log(data)
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
              if (item.type == "brands") {
                return (
                  <Tab.Panel key={k}>
                    {/* {posts} */}
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {/*<ActivityFeedCreateCard isPage={true} />*/}
                      {brandsData.map((item, k) => {
                        let data = Object.entries(item)[0]
                        console.log(data)
                        let propData = Array.isArray(data[1])
                          ? data[1][0]
                          : data[1]
                        return <LikeHits dataType={data[0]} {...propData} />
                      })}
                    </div>
                  </Tab.Panel>
                )
              }
            })}
          </Tab.Panels>
        </Tab.Group>
      ) : (
        ""
      )}
    </div>
  )
}
export default function Liked({ props }) {
  const [list, setList] = useState(null)
  const { token } = useContext(StateContext).state
  const [name, setName] = useState("Items")
  const { userType } = useContext(AuthenticationContext)

  useEffect(async () => {
    let data = await getMyBookmarks(token)
    if (data) {
      console.log("data")
      console.log(data)
      setList(data)
    }
  }, [])
  if (userType.brandManager) return <AccessDenied />

  return (
    <LayoutPage metaTitle={"Your Liked"} metaDesc={""} thin>
      <BannerSimple
        heading={
          <>
            {name.toLowerCase() === "brands" ? "Your Followed " : "Your liked "}
            <span className="text-[#e43d30]">{name.toLowerCase()}</span>
          </>
        }
      />
      {list !== null ? (
        <div className="container mb-7">
          <Tabs list={list} updateTitle={setName} />
        </div>
      ) : (
        <>
          <div>
            <Skeleton dark width={"500px"} height={"40px"} />
            {/*<ActivityFeedCreateCard isPage={true} />*/}
            <div className="grid grid-cols-3 gap-4 mt-4">
              {Array(6)
                .fill(1)
                .map(v => ({
                  id: "",
                  title: "",
                  bundle: "default",
                  description: "",
                  cover: [""],
                  tags: [],
                  category: [],
                  type: "digital",
                  brand: {
                    name: "",
                    logo: "",
                  },
                }))
                .map((item, k) => (
                  <ProductGrid loading={true} {...item} />
                ))}
            </div>
          </div>
        </>
      )}
    </LayoutPage>
  )
}
